.custom-button-mailto {
    z-index: 10000;
    width: 50px;
    height: 50px;
    background-color: #8BC34A; /* Background color similar to the Intercom button */
    border-radius: 50%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    cursor: pointer;
    bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-button-mailto:hover {
    transform: scale(1.1); /* Slightly increase the size on hover */
}

@font-face {
  font-family: 'origin-reports';
  src:  url('fonts/origin-reports.eot?sl4cyx');
  src:  url('fonts/origin-reports.eot?sl4cyx#iefix') format('embedded-opentype'),
    url('fonts/origin-reports.ttf?sl4cyx') format('truetype'),
    url('fonts/origin-reports.woff?sl4cyx') format('woff'),
    url('fonts/origin-reports.svg?sl4cyx#origin-reports') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'origin-reports' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\e948";
  color: #488df4;
}
.icon-posts:before {
  content: "\e947";
  color: #488df4;
}
.icon-unlock:before {
  content: "\e900";
  color: #717d88;
}
.icon-warning:before {
  content: "\e901";
}
.icon-visibility-off:before {
  content: "\e902";
}
.icon-visibility:before {
  content: "\e903";
}
.icon-vertical:before {
  content: "\e904";
}
.icon-upload:before {
  content: "\e905";
}
.icon-swap:before {
  content: "\e906";
}
.icon-support:before {
  content: "\e907";
}
.icon-subscription:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e909";
}
.icon-settings:before {
  content: "\e90a";
}
.icon-selected:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\e90c";
}
.icon-report:before {
  content: "\e90d";
}
.icon-reorder:before {
  content: "\e90e";
  color: #717d88;
}
.icon-radio-on:before {
  content: "\e90f";
}
.icon-radio-off:before {
  content: "\e910";
}
.icon-plus:before {
  content: "\e911";
}
.icon-organization:before {
  content: "\e912";
}
.icon-more-list:before {
  content: "\e913";
}
.icon-more:before {
  content: "\e914";
}
.icon-minus:before {
  content: "\e915";
}
.icon-members:before {
  content: "\e916";
}
.icon-log-out:before {
  content: "\e917";
}
.icon-lock:before {
  content: "\e918";
}
.icon-list:before {
  content: "\e919";
}
.icon-journal:before {
  content: "\e91a";
}
.icon-horizontal:before {
  content: "\e91b";
  color: #717d88;
}
.icon-home:before {
  content: "\e91c";
}
.icon-help-center:before {
  content: "\e91d";
}
.icon-help:before {
  content: "\e91e";
}
.icon-heart-empty:before {
  content: "\e91f";
}
.icon-heart:before {
  content: "\e920";
}
.icon-go-to:before {
  content: "\e921";
}
.icon-frame:before {
  content: "\e922";
}
.icon-format-underline:before {
  content: "\e923";
}
.icon-format-text-underline-color:before {
  content: "\e924";
}
.icon-format-text-color:before {
  content: "\e925";
}
.icon-format-italic:before {
  content: "\e926";
}
.icon-format-bold:before {
  content: "\e927";
}
.icon-format-align-right:before {
  content: "\e928";
}
.icon-format-align-left:before {
  content: "\e929";
}
.icon-format-align-center:before {
  content: "\e92a";
}
.icon-enterprise:before {
  content: "\e92b";
}
.icon-empty:before {
  content: "\e92c";
}
.icon-direct-delivery:before {
  content: "\e92a";
}
.icon-email:before {
  content: "\e92d";
}
.icon-edit:before {
  content: "\e92e";
}
.icon-dropdown:before {
  content: "\e92f";
}
.icon-download:before {
  content: "\e930";
}
.icon-done:before {
  content: "\e931";
}
.icon-delete:before {
  content: "\e932";
}
.icon-datepicker:before {
  content: "\e933";
}
.icon-close:before {
  content: "\e934";
}
/* .icon-circle-done:before {
  content: "\e935";
} */
.icon-chart-wedge:before {
  content: "\e936";
}
.icon-chart-tree:before {
  content: "\e937";
}
.icon-chart-table:before {
  content: "\e938";
}
.icon-chart-scatter-plot:before {
  content: "\e939";
}
.icon-chart-sankey:before {
  content: "\e93a";
}
.icon-chart-map:before {
  content: "\e93b";
}
.icon-chart-line:before {
  content: "\e93c";
}
.icon-chart-donut:before {
  content: "\e93d";
}
.icon-chart-bubble:before {
  content: "\e93e";
}
.icon-chart-bar:before {
  content: "\e93f";
}
.icon-chart:before {
  content: "\e940";
}
.icon-cancel:before {
  content: "\e941";
}
.icon-brush:before {
  content: "\e942";
}
.icon-block:before {
  content: "\e943";
}
.icon-arrow-right:before {
  content: "\e944";
}
.icon-arrow-back:before {
  content: "\e945";
}
.icon-add-person:before {
  content: "\e946";
}
.icon-add:before {
  content: "\902";
}
.icon-activity:before {
  content: "\e9";
}
.icon-account:before {
  content: "\e949";
}
.icon-intercom_account:before {
  content: "\e916";
}

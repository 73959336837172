* {
  margin: 0;
  box-sizing: border-box;
  padding: 0 ;
  font-family: "Inter" !important;
}
#root{
  margin: 0;
  box-sizing: border-box;
  padding: 0 ;
  font-family: "Inter" !important;
}
html,
body {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  /* background: #f7f7f7; */
  background: #fff;
  line-height: 1.4285em;
  scroll-behavior: smooth !important;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.28571429em;
  font-weight: 700;
  padding: 0;
  font-family: "Inter", sans-serif !important;
}

p {
  line-height: 1.4285em;
}

svg {
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: #e5e5e5;
}
::-webkit-scrollbar-thumb {
  background-color: #bed89c;
}

.disabled {
  color: #d1d1d1;
}

.Toastify__toast {
  background-color: #212121;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  text-align: center;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.public-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.public-main {
  background-color: #f8f9fa;
  flex-grow: 1;
}

.public-main,
.public-footer {
  flex-shrink: 0;
}

/* @import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700,900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Eczar:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Toastify__toast {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: unset;
  padding: 8px 16px !important;
}
.zoom-container {
  display: none !important
}
svg:hover ~ .zoom-container {
  display: block !important
}
.zoom-container:hover{
  display: block !important
}

.new-style-radio {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid #F2F4F7;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(119, 119, 119, 0.25) inset;
}

.new-style-radio-container {
  max-width: 382px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #041527;
  font-size: 16px;
  cursor: pointer;
}

.both-custom {
  position: absolute;
  background-color: white;
  z-index: 1000;
  border-radius: 8px;
  border: 1px solid var(--grey-dividers, #F2F4F7);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  padding: 4px 0;
  width: 320px;
  margin-top: 4px;
}

.clear-filter {
  padding: 10px 16px;
  border-top: 1px solid #F2F4F7;
  color: #74BA23;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  .clear-filter-close {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-select-item-dif {
  border-radius: 0 !important;
  border: 0 !important;

}

.custom-select-item-active {
  background-color: #EEF7E5 !important;
}

.custom-select-item {
  background-color: white;
  position: relative;
  width: 100%;
  border: 1px solid #F2F4F7;
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
}
.custom-select-item  .both {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .custom-select-item  .both-main-other {
      padding: 10px 14px;
    }

    .custom-select-item  .both-secondary {
      width: 20px;
      height: 20px;
    }
      .custom-select-item   .both-circle {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 50px;
        border: 1px solid #D0D5DD;

        box-shadow: 0px 0px 6px 0px rgba(155, 161, 169, 0.20) inset;
      }
 
  

  .custom-others {
    padding: 4px 0;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    border: 1px solid #F2F4F7;
  }
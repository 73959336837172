$offset: 187;
$duration: 1.4s;
$connectTime: 1s; 
$reducedDistance: $offset / 50; 

.loading-path {
    stroke-dasharray: $offset;
    stroke-dashoffset: $offset; 
    transform-origin: center; 
    stroke-width: 10; 
    stroke-linecap: butt; 
    animation:
      dash $duration ease-in-out infinite, 
      colors ($duration * 100) ease-in-out infinite;
  }
  

@keyframes colors {
	0% { stroke: #74ba23 }
}

@keyframes dash {
    0%, 33.333% { 
      stroke-dashoffset: $offset;
      transform: rotate(-80deg); 
    }
    33.333% {
      stroke-dashoffset: 0;
      transform: rotate(-90deg); 
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(260deg); 
    }
   }
   

.main-footer-container {
  // height: 70px;
  color: #6a6a6a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  background-color: #faf8f8;
  font-family: Inter;

  .info-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #9c9c9c;
    padding-top: 29px;
    padding-bottom: 28px;
  }

  .links-body {
    width: 619px;
    display: flex;
    justify-content: space-between;
    a,
    span {
      text-decoration: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color:#717D88;

    }
    span {
      cursor: pointer;
    }
  }

  .links-body-landing {
    // margin-top: 68px;
    margin-top: 40px;
    width: 619px;
    display: flex;
    justify-content: space-between;
     a,
     span {
      text-decoration: none;
      margin-bottom: 28px;
      color: #212121;
    }
    span {
      cursor: pointer;
    }
  }

  .landing-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 72px;
    margin-top: 10px;
    // margin-bottom: 36px;
    margin-bottom: 5px;
    & span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #212121;
    }
  }

  @media only screen and (max-width: 1024px) {
    .landing-text {
      // margin-top: 34px;
      margin-top: 20px;
    }
    .links-body-landing {
      width: 200px;
      flex-direction: column;
      align-items: center;
      & a {
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 28px;
        color: #212121;
      }
      & span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 28px;
        color: #212121;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    .links-body {
      width: 510px;
    }
  }
}

.main-footer-container-updated {
  background-color: #FFF;
  position: relative;

  .links-body {
    a {
      color: #5a5a5a;
      font-size: 14px;
    }
  }

  .info-text {
    padding: 22px 0 12px;
  }
}